/* Base styles */
.title{
    font-size:2rem;
    font-family: "Assistant";
    text-align: center;
    margin: 2% auto;
    direction: rtl;
    background: linear-gradient(135deg, 
      #e4bf6e,  /* בז' מוזהב */
      #c7b277,  /* זהב בהיר */
      #a89254,  /* זהב בינוני */
      #d6c69b,  /* זהב קלאסי */
      #cdac65   /* זהב עדין בהיר */
    );

    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    color: transparent;
    text-shadow: 2px 2px 4px rgba(242, 222, 175, 0.2);
}

.row {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    flex-wrap: wrap;
    margin: 2% auto;
}

.description {
    font-family: 'AssistantR';
    font-size: 1.4rem;
    text-align: center;
    color: black;
    direction: rtl;
    width:70%;
    margin: 1% auto;
}

/* Responsive styles */
@media (max-width: 350px) {
    .title {
        font-size: 1.8rem;
        margin: 1% auto;
    }
    .description {
        font-size: 1rem;
        width:90%;
    }
    .row {
        flex-direction: column;
        align-items: center;
    }
}

@media (min-width: 350px) and (max-width: 450px) {
    .title {
        font-size: 2rem;
    }
    .description {
        font-size: 1.1rem;
        width:90%;
    }
}

@media (min-width: 450px) and (max-width: 550px) {
    .title {
        font-size: 2.2rem;
    }
    .description {
        font-size: 1.2rem;
        width:80%;
    }
}

@media (min-width: 550px) and (max-width: 650px) {
    .title {
        font-size: 2.3rem;
    }
    .description {
        font-size: 1.3rem;
    }
}

@media (min-width: 650px) and (max-width: 750px) {
    .title {
        font-size: 2.4rem;
    }
}

@media (min-width: 750px) and (max-width: 850px) {
    /* Default styles apply */
}

@media (min-width: 850px) and (max-width: 950px) {
    .title {
        font-size: 2.6rem;
    }
    .description {
        font-size: 1.5rem;
    }
}

@media (min-width: 950px) and (max-width: 1050px) {
    .title {
        font-size: 2.7rem;
    }
    .description {
        font-size: 1.6rem;
    }
}

@media (min-width: 1050px) and (max-width: 1150px) {
    .title {
        font-size: 2.8rem;
    }
    .description {
        font-size: 1.7rem;
    }
}

@media (min-width: 1150px) and (max-width: 1250px) {
    .title {
        font-size: 2.9rem;
    }
    .description {
        font-size: 1.8rem;
    }
}