.title {
    font-family: "Assistant";
    text-align: center;
    margin: 3% auto;
    direction: rtl;
    background: linear-gradient(135deg, 
      #e4bf6e,  /* בז' מוזהב */
      #c7b277,  /* זהב בהיר */
      #a89254,  /* זהב בינוני */
      #d6c69b,  /* זהב קלאסי */
      #cdac65   /* זהב עדין בהיר */
    );

    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    color: transparent;
    text-shadow: 2px 2px 4px rgba(242, 222, 175, 0.2);
}

/* מתחת ל-350px */
@media (max-width: 350px) {
    .title {
        font-size: 1.5rem;
    }
}

/* 350px - 450px */
@media (min-width: 350px) and (max-width: 450px) {
    .title {
        font-size: 1.7rem;
    }
}

/* 450px - 550px */
@media (min-width: 450px) and (max-width: 550px) {
    .title {
        font-size: 1.9rem;
    }
}

/* 550px - 650px */
@media (min-width: 550px) and (max-width: 650px) {
    .title {
        font-size: 2.1rem;
    }
}

/* 650px - 750px */
@media (min-width: 650px) and (max-width: 750px) {
    .title {
        font-size: 2.3rem;
    }
}

/* 750px - 850px */
@media (min-width: 750px) and (max-width: 850px) {
    .title {
        font-size: 2.5rem;
    }
}

/* 850px - 950px */
@media (min-width: 850px) and (max-width: 950px) {
    .title {
        font-size: 2.7rem;
    }
}

/* 950px - 1050px */
@media (min-width: 950px) and (max-width: 1050px) {
    .title {
        font-size: 2.9rem;
    }
}

/* 1050px - 1150px */
@media (min-width: 1050px) and (max-width: 1150px) {
    .title {
        font-size: 3.1rem;
    }
}

/* 1150px - 1250px */
@media (min-width: 1150px) and (max-width: 1250px) {
    .title {
        font-size: 3.3rem;
    }
}

/* מעל 1250px */
@media (min-width: 1250px) {
    .title {
        font-size: 3.5rem;
    }
}