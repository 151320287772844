.container {
  max-width: 1000px;
  margin: 0 auto;
  padding: 20px;
  font-family: 'AssistantR', sans-serif;
  color: #4a4a4a;
  background-color: #fffdf9;
  direction: rtl;
  border-radius: 1rem;

}

.title {
  font-family: 'Assistant', sans-serif;
  color: #d6c69b;
  text-align: center;
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.sectionTitle {
  font-family: 'Assistant', sans-serif;
  color: #c9b995;
  margin-top: 30px;
  margin-bottom: 15px;
  font-size: 1.8rem;
  text-align: right;
  direction: rtl;
  padding-right: 20px;
}

.card {
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 20px;
  direction: rtl;
  max-width: 90%;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 
    5px 5px 10px rgba(214, 198, 155, 0.15),
    -5px -5px 10px rgba(255, 255, 255, 0.5),
    inset 3px 3px 6px rgba(214, 198, 155, 0.08),
    inset -3px -3px 6px rgba(214, 198, 155, 0.08);
}

.list {
  list-style-type: none;
  padding-right: 0;
}

.listItem {
  margin-bottom: 10px;
  position: relative;
  display: flex;
  align-items: center;
}

.icon {
  margin-left: 10px;
  color: #d6c69b;
  min-width: 24px;
}

.description, .footer {
  margin-bottom: 15px;
  line-height: 1.6;
}

.button {
  background-color: #d6c69b;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  display: block;
  margin: 0 auto;
}

/* Responsive styles */
@media (max-width: 350px) {
  .title { font-size: 1.8rem; }
  .sectionTitle { font-size: 1.4rem; }
  .description, .footer, .listItem { font-size: 0.9rem; }
  .card { max-width: 95%; }
}

@media (min-width: 350px) and (max-width: 450px) {
  .title { font-size: 2rem; }
  .sectionTitle { font-size: 1.5rem; }
  .description, .footer, .listItem { font-size: 0.95rem; }
  .card { max-width: 95%; }
}

@media (min-width: 450px) and (max-width: 550px) {
  .title { font-size: 2.2rem; }
  .sectionTitle { font-size: 1.6rem; }
  .card { max-width: 92%; }
}

@media (min-width: 550px) and (max-width: 650px) {
  .container { padding: 25px; }
  .card { max-width: 90%; }
}

@media (min-width: 650px) and (max-width: 750px) {
  .container { padding: 30px; }
  .card { max-width: 88%; }
}

@media (min-width: 750px) and (max-width: 850px) {
  .container { padding: 35px; }
  .card { max-width: 86%; }
}

@media (min-width: 850px) and (max-width: 950px) {
  .container { padding: 40px; }
  .card { max-width: 84%; }
}

@media (min-width: 950px) {
  .container { padding: 45px; }
  .card { max-width: 82%; }
}